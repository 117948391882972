import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4546146e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_i18n_t = _resolveComponent("i18n-t");
  var _component_confirm_dialog = _resolveComponent("confirm-dialog");
  var _component_CenterCenter = _resolveComponent("CenterCenter");
  return _openBlock(), _createBlock(_component_CenterCenter, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_Banner, {
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
        }),
        _: 1
      }), _createVNode(_component_w_icon, {
        icon: "emoticon-sad",
        size: "48px",
        class: "error-icon"
      })], 64)) : _createCommentVNode("", true), _ctx.isComponentMounted ? (_openBlock(), _createBlock(_component_confirm_dialog, {
        key: 1,
        title: _ctx.$t("account_initialization_dialog.".concat(_ctx.initializationMode, ".title")).toString(),
        visible: true,
        "confirm-btn-title": _ctx.$t("account_initialization_dialog.".concat(_ctx.initializationMode, ".confirm_btn")).toString(),
        onOnConfirmed: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.autoLogin();
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_i18n_t, {
            scope: "global",
            keypath: "account_initialization_dialog.".concat(_ctx.initializationMode, ".message.value"),
            tag: "div"
          }, {
            link_nbUsage: _withCtx(function () {
              return [_createElementVNode("b", null, _toDisplayString(_ctx.$t("account_initialization_dialog.".concat(_ctx.initializationMode, ".message.link_nbUsage"))) + ". ", 1)];
            }),
            link_duration: _withCtx(function () {
              return [_createElementVNode("b", null, _toDisplayString(_ctx.$t("account_initialization_dialog.".concat(_ctx.initializationMode, ".message.link_duration"))), 1)];
            }),
            _: 1
          }, 8, ["keypath"])];
        }),
        _: 1
      }, 8, ["title", "confirm-btn-title"])) : _createCommentVNode("", true)])];
    }),
    _: 1
  });
}