import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9b7d5daa"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = {
  key: 0,
  class: "platform-version"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_MainTitle = _resolveComponent("MainTitle");
  var _component_Banner = _resolveComponent("Banner");
  var _component_Separator = _resolveComponent("Separator");
  var _component_SummaryItem = _resolveComponent("SummaryItem");
  var _component_grid_item = _resolveComponent("grid-item");
  var _component_grid_layout = _resolveComponent("grid-layout");
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_settings_platform = _resolveComponent("settings-platform");
  var _component_ip_whitelist_settings = _resolveComponent("ip-whitelist-settings");
  var _component_el_tabs = _resolveComponent("el-tabs");
  var _component_CenterTop = _resolveComponent("CenterTop");
  var _directive_loading = _resolveDirective("loading");
  return _ctx.platform ? (_openBlock(), _createBlock(_component_CenterTop, {
    key: 0
  }, {
    default: _withCtx(function () {
      return [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MainTitle, {
        "breadcrumb-items": _ctx.breadcrumbItems
      }, {
        title: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.platform.name) + " ", 1), _ctx.platformVersion ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.platformVersion), 1)) : _createCommentVNode("", true), _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.goToPlatform();
            })
          }, [_createVNode(_component_w_icon, {
            icon: "open-in-new",
            size: "20px",
            class: "title-icon"
          })])];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_w_icon, {
            icon: "refresh",
            size: "25px",
            class: "refresh-icon",
            onClick: _ctx.refreshInformationPlatform
          }, null, 8, ["onClick"]), _createVNode(_component_w_standard_button, {
            class: "remove-connection-btn",
            type: "danger",
            minWidth: "223px",
            disabled: !_ctx.isUserCanDisconnect,
            onClick: _ctx.deleteMe
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_w_icon, {
                icon: "link-variant-off"
              }), _createTextVNode(" " + _toDisplayString(_ctx.$t('platform_detail.unlink_platform_label')), 1)];
            }),
            _: 1
          }, 8, ["disabled", "onClick"])];
        }),
        _: 1
      }, 8, ["breadcrumb-items"]), _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeTabName,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return _ctx.activeTabName = $event;
        }),
        onTabClick: _ctx.handleTabClick,
        class: _normalizeClass("ptf-detail-tab ".concat(_ctx.isIPWhitelistingAvailable ? 'ip-whitelisting-tab-visible' : ''))
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_tab_pane, {
            label: _ctx.$t('platform_detail.overview.title'),
            name: _ctx.overviewTabName,
            class: "ptf-detail-tab__item"
          }, {
            default: _withCtx(function () {
              return [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
                key: 0,
                type: "error"
              }, {
                default: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
                }),
                _: 1
              })) : _createCommentVNode("", true), _createVNode(_component_Separator, {
                label: _ctx.$t('platform_detail.overview.speach_publish_section.title'),
                icon: "playlist-edit",
                color: _ctx.platform.color
              }, null, 8, ["label", "color"]), _createVNode(_component_grid_layout, null, {
                default: _withCtx(function () {
                  return [_createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.speach_publish_section.published_speaches'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachPublishKey,
                        shortcutUrl: _ctx.speachAdminUrl
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.speach_publish_section.speaches_to_publish'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachToPublishKey,
                        "zero-class": "good",
                        shortcutUrl: _ctx.speachAdminUrlByState('DRAFT')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.speach_publish_section.speaches_to_validate'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachToValidateKey,
                        "zero-class": "good",
                        shortcutUrl: _ctx.platform.url + '/administration/listWorkflow'
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _createVNode(_component_Separator, {
                icon: "trending-up",
                color: _ctx.platform.color,
                label: _ctx.$t('platform_detail.overview.trending_section.title')
              }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
                default: _withCtx(function () {
                  return [_createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.new_speaches_this_week'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbNewSpeachWeekKey,
                        "zero-class": "bad",
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('ONE_MONTH')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.new_speaches_last_month'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbNewSpeachMonthKey,
                        "zero-class": "bad",
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('ONE_MONTH')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.new_speaches_last_3_month'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbNewSpeach3MonthKey,
                        "zero-class": "bad",
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('THREE_MONTHS')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.new_speaches_last_6_month'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbNewSpeach6MonthKey,
                        "zero-class": "bad",
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('SIX_MONTHS')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _ctx.nbUniqueReadDataExist ? (_openBlock(), _createBlock(_component_grid_layout, {
                key: 1
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.nb_speach_unique_views_1Week'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachUniqueRead1Week,
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('ONE_MONTH')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.nb_speach_unique_views_1Month'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachUniqueRead1Month,
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('ONE_MONTH')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.nb_speach_unique_views_3Months'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachUniqueRead3Months,
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('THREE_MONTHS')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.trending_section.nb_speach_unique_views_6Months'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachUniqueRead6Months,
                        shortcutUrl: _ctx.getSpeachAdminDashboardUrlByPeriod('SIX_MONTHS')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })) : _createCommentVNode("", true), _createVNode(_component_Separator, {
                icon: "account-group-outline",
                color: _ctx.platform.color,
                label: _ctx.$t('platform_detail.overview.users_section.title')
              }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
                default: _withCtx(function () {
                  return [_createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.users_section.users'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbUserKey,
                        shortcutUrl: _ctx.userAdminUrl
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.users_section.speach_maker'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbSpeachMakerKey,
                        shortcutUrl: _ctx.userAdminUrlByRole('FORMATEUR'),
                        "zero-class": "bad"
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.users_section.administrators'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbAdminKey,
                        "zero-class": "bad",
                        shortcutUrl: _ctx.userAdminUrlByRole('ADMIN')
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  }), _createVNode(_component_grid_item, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SummaryItem, {
                        label: _ctx.$t('platform_detail.overview.users_section.users_connected_this_month'),
                        platform: _ctx.platform,
                        keyValue: _ctx.nbConnectedMonthKey,
                        shortcutUrl: _ctx.platform.url + '/administration/consommation',
                        "zero-class": "bad"
                      }, null, 8, ["label", "platform", "keyValue", "shortcutUrl"])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          }, 8, ["label", "name"]), _ctx.isSettingAvailable ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 0,
            label: _ctx.$t('platform_detail.settings_view.title'),
            name: _ctx.settingsTabName,
            class: "ptf-detail-tab__item"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_settings_platform, {
                platformID: _ctx.platformID
              }, null, 8, ["platformID"])];
            }),
            _: 1
          }, 8, ["label", "name"])) : _createCommentVNode("", true), _ctx.isIPWhitelistingAvailable ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 1,
            label: _ctx.$t('platform_detail.ip_whitelisting_view.title'),
            name: _ctx.ipWhitelistTabName,
            class: "ptf-detail-tab__item"
          }, {
            default: _withCtx(function () {
              return [_ctx.activeTabName === _ctx.ipWhitelistTabName ? (_openBlock(), _createBlock(_component_ip_whitelist_settings, {
                key: 0,
                platformID: _ctx.platformID
              }, null, 8, ["platformID"])) : _createCommentVNode("", true)];
            }),
            _: 1
          }, 8, ["label", "name"])) : _createCommentVNode("", true), _createVNode(_component_el_tab_pane, {
            class: "ptf-detail-tab__item ptf-detail-link-tab",
            disabled: true
          }, {
            label: _withCtx(function () {
              return [_createElementVNode("a", {
                class: "go-to-platform-url",
                onClick: _cache[1] || (_cache[1] = function ($event) {
                  return _ctx.goToPlatformSpeachManagement();
                })
              }, [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.go_to_speaches_management_label')) + " ", 1), _createVNode(_component_w_icon, {
                icon: "open-in-new",
                size: "12px"
              })])];
            }),
            _: 1
          }), _createVNode(_component_el_tab_pane, {
            class: "ptf-detail-tab__item link-tab",
            disabled: true
          }, {
            label: _withCtx(function () {
              return [_createElementVNode("a", {
                class: "go-to-platform-url",
                onClick: _cache[2] || (_cache[2] = function ($event) {
                  return _ctx.goToPlatformUsersManagement();
                })
              }, [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.go_to_users_management_label')) + " ", 1), _createVNode(_component_w_icon, {
                icon: "open-in-new",
                size: "12px"
              })])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["modelValue", "onTabClick", "class"])])), [[_directive_loading, _ctx.loading]])];
    }),
    _: 1
  })) : _createCommentVNode("", true);
}