import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d788da50"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "mainTitle"
};
var _hoisted_2 = {
  class: "blocktitle"
};
var _hoisted_3 = {
  key: 0,
  class: "breadcrumb-block"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "blockright"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$breadcrumbItems;
  var _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  var _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)]), (_ctx$breadcrumbItems = _ctx.breadcrumbItems) !== null && _ctx$breadcrumbItems !== void 0 && _ctx$breadcrumbItems.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_breadcrumb, {
    separator: ">"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbItems, function (item) {
        return _openBlock(), _createElementBlock(_Fragment, null, [item.isRouterLink ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
          key: 0,
          to: {
            path: item.url
          }
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(item.label), 1)];
          }),
          _: 2
        }, 1032, ["to"])) : item.url ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
          key: 1
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("a", {
              onClick: function onClick($event) {
                return _ctx.goTo(item.url);
              }
            }, _toDisplayString(item.label), 9, _hoisted_4)];
          }),
          _: 2
        }, 1024)) : (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
          key: 2
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(item.label), 1)];
          }),
          _: 2
        }, 1024))], 64);
      }), 256))];
    }),
    _: 1
  })])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}