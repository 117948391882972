import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-28d635d4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_MainTitle = _resolveComponent("MainTitle");
  var _component_UsersTable = _resolveComponent("UsersTable");
  var _component_add_user = _resolveComponent("add-user");
  var _component_CenterTop = _resolveComponent("CenterTop");
  return _openBlock(), _createBlock(_component_CenterTop, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MainTitle, null, {
        title: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('users_management.title')), 1)];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_w_standard_button, {
            minHeight: "32px",
            minWidth: "170px",
            disabled: !_ctx.isAddUser,
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.toggleUserCreationDialog = true;
            })
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_w_icon, {
                icon: "plus"
              }), _createTextVNode(" " + _toDisplayString(_ctx.$t('users_management.create_user_btn')), 1)];
            }),
            _: 1
          }, 8, ["disabled"])];
        }),
        _: 1
      }), _createVNode(_component_UsersTable)]), _createVNode(_component_add_user, {
        "toggle-user-creation-dialog": _ctx.toggleUserCreationDialog,
        onDialogClose: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.toggleUserCreationDialog = false;
        })
      }, null, 8, ["toggle-user-creation-dialog"])];
    }),
    _: 1
  });
}