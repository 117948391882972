import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4475204a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "auth-links-table"
};
var _hoisted_2 = {
  class: "auth-link-platform-column"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "auth-link-platform-column__value"
};
var _hoisted_5 = {
  slot: "empty"
};
var _hoisted_6 = {
  class: "no-result-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_w_icon_button = _resolveComponent("w-icon-button");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_table = _resolveComponent("el-table");
  var _component_auth_link_form = _resolveComponent("auth-link-form");
  var _component_auth_link_delete_dialog = _resolveComponent("auth-link-delete-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_table, {
    ref: "tableAuthLink",
    data: _ctx.authLinks
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        label: _ctx.$t('authentication_links_management.authentication_links_table.platform_col').toString()
      }, {
        default: _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
            src: scope.row.platform.logo,
            class: "auth-link-platform-column__logo",
            alt: ""
          }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, _toDisplayString(scope.row.platform.name), 1)])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "authItem.name",
        label: _ctx.$t('authentication_links_management.authentication_links_table.auth_name_col').toString()
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "authItem.type",
        label: _ctx.$t('authentication_links_management.authentication_links_table.auth_type_col').toString()
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: "",
        width: "100px"
      }, {
        default: _withCtx(function (scope) {
          return [_createVNode(_component_w_icon_button, {
            iconClass: "pencil-outline",
            class: "action-btn auth-link-edit-btn",
            disabled: !_ctx.isDeleteAuthLink,
            onClick: function onClick($event) {
              return _ctx.updateAuthLink(scope.row);
            }
          }, null, 8, ["disabled", "onClick"]), _createVNode(_component_w_icon_button, {
            iconClass: "delete-outline",
            class: "action-btn auth-link-delete-btn",
            disabled: !_ctx.isDeleteAuthLink,
            onClick: function onClick($event) {
              return _ctx.deleteAuthLink(scope.row);
            }
          }, null, 8, ["disabled", "onClick"])];
        }),
        _: 1
      }), _createElementVNode("template", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_w_icon, {
        icon: "emoticon-sad-outline"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('authentication_links_management.authentication_links_table.on_no_result_found').toString()), 1)])])];
    }),
    _: 1
  }, 8, ["data"]), !!_ctx.authLinkIdToUpdate ? (_openBlock(), _createBlock(_component_auth_link_form, {
    key: 1,
    "link-id": _ctx.authLinkIdToUpdate,
    "toggle-auth-link-form-dialog": _ctx.toggleAuthLinkFormDialog,
    onDialogClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.toggleAuthLinkFormDialog = false;
    })
  }, null, 8, ["link-id", "toggle-auth-link-form-dialog"])) : _createCommentVNode("", true), _createVNode(_component_auth_link_delete_dialog, {
    "toggle-dialog": _ctx.toggleAuthLinkDeleteDialog,
    onDialogClose: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.toggleAuthLinkDeleteDialog = false;
    }),
    onDeleteConfirm: _ctx.confirmAuthLinkDelete
  }, null, 8, ["toggle-dialog", "onDeleteConfirm"])]);
}