import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-435f0d96"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "fullContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "container",
    style: _normalizeStyle(_ctx.backgroundStyle)
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 4)]);
}