import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-efa6d0b2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "color-picker"
};
var _hoisted_2 = {
  class: "color-picker__custom"
};
var _hoisted_3 = {
  class: "color-picker__predifined"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_text_input = _resolveComponent("w-text-input");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
    class: _normalizeClass("picker-trigger-wrapper ".concat(!_ctx.selectedColor ? 'no-color-picked' : '')),
    style: _normalizeStyle("background-color:".concat(_ctx.selectedColor))
  }, [_withDirectives(_createElementVNode("input", {
    type: "color",
    class: "picker-trigger",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.selectedColor = $event;
    }),
    onInput: _cache[1] || (_cache[1] =
    //@ts-ignore
    function () {
      return _ctx.onColorInputChanges && _ctx.onColorInputChanges.apply(_ctx, arguments);
    }),
    onBlur: _cache[2] || (_cache[2] =
    //@ts-ignore
    function () {
      return _ctx.onBlur && _ctx.onBlur.apply(_ctx, arguments);
    })
  }, null, 544), [[_vModelText, _ctx.selectedColor]])], 6), _createVNode(_component_w_text_input, {
    placeholder: "#000000",
    value: _ctx.selectedColor,
    width: _ctx.width,
    onInputChanged: _ctx.updateSelectedColor,
    onBlur: _ctx.onBlur
  }, null, 8, ["value", "width", "onInputChanged", "onBlur"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "colorSelectionPointer orange",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.selectAColor('#F8550F');
    })
  }), _createElementVNode("div", {
    class: "colorSelectionPointer blue",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.selectAColor('#0096ff');
    })
  }), _createElementVNode("div", {
    class: "colorSelectionPointer green",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.selectAColor('#A2F896');
    })
  }), _createElementVNode("div", {
    class: "colorSelectionPointer violet",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.selectAColor('#663cf2');
    })
  }), _createElementVNode("div", {
    class: "colorSelectionPointer blueDark",
    onClick: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.selectAColor('#0051ff');
    })
  })])]);
}