import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1097e7c2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "platContainer"
};
var _hoisted_2 = {
  class: "topline"
};
var _hoisted_3 = {
  class: "cellLogo"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "cellLabel"
};
var _hoisted_6 = {
  class: "contentFrame"
};
var _hoisted_7 = {
  class: "gridPreview"
};
var _hoisted_8 = {
  class: "items-row first-items-row"
};
var _hoisted_9 = {
  class: "item"
};
var _hoisted_10 = {
  class: "itemFirstLabel"
};
var _hoisted_11 = {
  class: "itemNumber"
};
var _hoisted_12 = {
  class: "item"
};
var _hoisted_13 = {
  class: "itemFirstLabel"
};
var _hoisted_14 = {
  class: "item"
};
var _hoisted_15 = {
  class: "itemFirstLabel"
};
var _hoisted_16 = {
  class: "items-row second-items-row"
};
var _hoisted_17 = {
  class: "item"
};
var _hoisted_18 = {
  class: "itemLabel"
};
var _hoisted_19 = {
  class: "itemNumber"
};
var _hoisted_20 = {
  class: "item"
};
var _hoisted_21 = {
  class: "itemLabel"
};
var _hoisted_22 = {
  class: "item"
};
var _hoisted_23 = {
  class: "itemLabel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_NumberAnimation = _resolveComponent("NumberAnimation");
  var _component_CardInteractive = _resolveComponent("CardInteractive");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createBlock(_component_router_link, {
    to: {
      name: 'platformDetail',
      params: {
        platformID: _ctx.platform.platformID,
        name: _ctx.platform.name
      }
    },
    class: "platform-preview-link"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_CardInteractive, null, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
            src: _ctx.logo
          }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.name), 1)]), _createElementVNode("div", _hoisted_6, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
            key: 0,
            type: "error"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
            }),
            _: 1
          })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platform_detail.speaches')), 1), _createElementVNode("div", _hoisted_11, [_createVNode(_component_NumberAnimation, {
            to: _ctx.nbSpeachPublish,
            duration: 1,
            delay: 0.25
          }, null, 8, ["to"])])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platform_detail.by_week')), 1), _createElementVNode("div", {
            class: _normalizeClass(["itemNumber", {
              bad: _ctx.nbNewSpeachWeek == 0
            }])
          }, [_createVNode(_component_NumberAnimation, {
            to: _ctx.nbNewSpeachWeek,
            duration: 1,
            delay: 0.25
          }, null, 8, ["to"])], 2)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platform_detail.by_month')), 1), _createElementVNode("div", {
            class: _normalizeClass(["itemNumber", {
              bad: _ctx.nbNewSpeachMonth == 0
            }])
          }, [_createVNode(_component_NumberAnimation, {
            to: _ctx.nbNewSpeachMonth,
            duration: 1,
            delay: 0.25
          }, null, 8, ["to"])], 2)])]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platform_detail.users')), 1), _createElementVNode("div", _hoisted_19, [_createVNode(_component_NumberAnimation, {
            to: _ctx.nbUser,
            duration: 1,
            delay: 0.25
          }, null, 8, ["to"])])]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platform_detail.speach_maker')), 1), _createElementVNode("div", {
            class: _normalizeClass(["itemNumber", {
              good: _ctx.nbSpeachMakers == 0
            }])
          }, [_createVNode(_component_NumberAnimation, {
            to: _ctx.nbSpeachMakers,
            duration: 1,
            delay: 0.25
          }, null, 8, ["to"])], 2)]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platform_detail.administrators')), 1), _createElementVNode("div", {
            class: _normalizeClass(["itemNumber", {
              bad: _ctx.nbAdmins == 0
            }])
          }, [_createVNode(_component_NumberAnimation, {
            to: _ctx.nbAdmins,
            duration: 1,
            delay: 0.25
          }, null, 8, ["to"])], 2)])])])])])];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["to"]);
}