import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ffd2ba90"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "item"
};
var _hoisted_2 = {
  class: "item__number-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_NumberAnimation = _resolveComponent("NumberAnimation");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createTextVNode(_toDisplayString(_ctx.title) + " ", 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_NumberAnimation, {
    to: _ctx.total,
    duration: 3,
    delay: 0
  }, null, 8, ["to"])])]);
}