import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1f53d130"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "dialog-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.title,
    onDialogClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.onDialogClose();
    })
  }, {
    footer: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "footer", {}, undefined, true)];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])];
    }),
    _: 3
  }, 8, ["visible", "title"]);
}