import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "separatorContainer"
};
var _hoisted_2 = {
  class: "icon-block"
};
var _hoisted_3 = {
  class: "separatorLabel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_icon = _resolveComponent("w-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_w_icon, {
    icon: _ctx.icon,
    size: "22px",
    style: _normalizeStyle(_ctx.loadingStyle)
  }, null, 8, ["icon", "style"])]), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)]);
}