import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_table = _resolveComponent("header-table");
  var _component_title_cell = _resolveComponent("title-cell");
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_user_cell = _resolveComponent("user-cell");
  var _component_cell = _resolveComponent("cell");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createBlock(_component_el_table, {
    data: _ctx.listSpeach,
    class: "speach-result-table",
    size: "large"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        field: "title",
        width: "450"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_result_process.table.title_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          return [_createVNode(_component_title_cell, {
            title: scope.row.cour.title,
            image: scope.row.cour.image
          }, null, 8, ["title", "image"])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "author",
        "min-width": "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_result_process.table.author_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          var _scope$row$userCopy, _scope$row$userCopy2, _scope$row$userCopy3, _scope$row$userCopy4;
          return [_createVNode(_component_user_cell, {
            image: (_scope$row$userCopy = scope.row.userCopy) === null || _scope$row$userCopy === void 0 ? void 0 : _scope$row$userCopy.image,
            "first-name": (_scope$row$userCopy2 = scope.row.userCopy) === null || _scope$row$userCopy2 === void 0 ? void 0 : _scope$row$userCopy2.firstName,
            "last-name": (_scope$row$userCopy3 = scope.row.userCopy) === null || _scope$row$userCopy3 === void 0 ? void 0 : _scope$row$userCopy3.lastName,
            email: (_scope$row$userCopy4 = scope.row.userCopy) === null || _scope$row$userCopy4 === void 0 ? void 0 : _scope$row$userCopy4.email
          }, null, 8, ["image", "first-name", "last-name", "email"])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "category",
        "min-width": "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_result_process.table.category_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          var _scope$row$categoryCo;
          return [_createVNode(_component_cell, {
            text: (_scope$row$categoryCo = scope.row.categoryCopy) === null || _scope$row$categoryCo === void 0 ? void 0 : _scope$row$categoryCo.name
          }, null, 8, ["text"])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "status",
        width: "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_result_process.table.status_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          return [_createVNode(_component_w_icon, {
            icon: _ctx.getIconFromStatus(scope.row.copySpeachStatus),
            class: _normalizeClass("copy-speach-status-icon ".concat(_ctx.getTypeFromStatus(scope.row.copySpeachStatus)))
          }, null, 8, ["icon", "class"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["data"]);
}