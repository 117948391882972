import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");
  var _component_w_select = _resolveComponent("w-select");
  return _openBlock(), _createBlock(_component_w_select, {
    placeholder: _ctx.placeholder,
    name: _ctx.name,
    value: _ctx.realValue,
    width: "100%",
    class: "select-category",
    onChange: _ctx.onChangeValue
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, function (category) {
        return _openBlock(), _createBlock(_component_el_option, {
          value: (category === null || category === void 0 ? void 0 : category.id) || '',
          key: category === null || category === void 0 ? void 0 : category.id,
          label: category === null || category === void 0 ? void 0 : category.name
        }, null, 8, ["value", "label"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["placeholder", "name", "value", "onChange"]);
}