import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6b82ce35"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "summaryContainer"
};
var _hoisted_2 = {
  class: "item"
};
var _hoisted_3 = {
  class: "item__number-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_NumberAnimation = _resolveComponent("NumberAnimation");
  var _component_CardInteractive = _resolveComponent("CardInteractive");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.openSummaryDetail(_ctx.shortcutUrl, _ctx.platform.platformID);
    })
  }, [_createVNode(_component_CardInteractive, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.label) + " ", 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_NumberAnimation, {
        to: _ctx.value,
        duration: 1,
        delay: 0.25
      }, null, 8, ["to"])])])];
    }),
    _: 1
  })])]);
}