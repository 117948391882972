import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-19284177"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "table-and-actions"
};
var _hoisted_2 = {
  key: 0,
  class: "action-bar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_header_table = _resolveComponent("header-table");
  var _component_title_cell = _resolveComponent("title-cell");
  var _component_search_user = _resolveComponent("search-user");
  var _component_select_category = _resolveComponent("select-category");
  var _component_el_table = _resolveComponent("el-table");
  var _component_i18n_t = _resolveComponent("i18n-t");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _directive_observe_visibility = _resolveDirective("observe-visibility");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    ref: _ctx.tableRef,
    data: _ctx.listSpeach,
    stripe: "",
    size: "large",
    onSelect: _ctx.updateCheckedRows,
    onSelectAll: _ctx.updateCheckedRows
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        type: "selection",
        width: "55"
      }), _createVNode(_component_el_table_column, {
        field: "title",
        width: "450"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_setup.table.title_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          return [_withDirectives((_openBlock(), _createElementBlock("div", null, [scope.row.cour.title ? (_openBlock(), _createBlock(_component_title_cell, {
            key: 0,
            title: scope.row.cour.title,
            image: scope.row.cour.image
          }, null, 8, ["title", "image"])) : _createCommentVNode("", true)])), [[_directive_observe_visibility, function (isVisible) {
            return _ctx.visibilityChanged(isVisible, scope.row.cour);
          }]])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "author",
        "min-width": "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_setup.table.author_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          return [_createVNode(_component_search_user, {
            onUserChange: function onUserChange($event) {
              return _ctx.changeAuthor($event, scope.row);
            },
            value: scope.row.userCopy,
            platform: _ctx.destinationPlatform,
            placeholder: _ctx.$t('copy_speaches.speaches_setup.table.new_author_placeholder').toString()
          }, null, 8, ["onUserChange", "value", "platform", "placeholder"])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "category",
        "min-width": "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_setup.table.category_col').toString()
          }, null, 8, ["label"])];
        }),
        default: _withCtx(function (scope) {
          return [_createVNode(_component_select_category, {
            onInput: function onInput($event) {
              return _ctx.changeCategory($event, scope.row);
            },
            value: scope.row.categoryCopy,
            placeholder: _ctx.$t('copy_speaches.speaches_setup.table.new_category_placeholder').toString(),
            platform: _ctx.destinationPlatform
          }, null, 8, ["onInput", "value", "placeholder", "platform"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["data", "onSelect", "onSelectAll"]), _ctx.numberOfSpeachesChecked ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_i18n_t, {
    scope: "global",
    keypath: "copy_speaches.speaches_setup.table.speaches_selected",
    class: "text-action-bar"
  }, {
    numberOfSpeaches: _withCtx(function () {
      return [_createElementVNode("b", null, _toDisplayString(_ctx.numberOfSpeachesChecked), 1)];
    }),
    _: 1
  }), _createVNode(_component_search_user, {
    onUserChange: _ctx.changeAuthorActionBar,
    platform: _ctx.destinationPlatform,
    placeholder: _ctx.$t('copy_speaches.speaches_setup.table.new_author_placeholder').toString(),
    class: "input-action-bar"
  }, null, 8, ["onUserChange", "platform", "placeholder"]), _createVNode(_component_select_category, {
    onInput: _ctx.changeCategoryActionBar,
    placeholder: _ctx.$t('copy_speaches.speaches_setup.table.new_category_placeholder').toString(),
    platform: _ctx.destinationPlatform,
    class: "input-action-bar"
  }, null, 8, ["onInput", "placeholder", "platform"]), _createVNode(_component_w_standard_button, {
    type: "default",
    onClick: _ctx.cancelActionBar
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('copy_speaches.speaches_setup.table.cancel').toString()), 1)];
    }),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_w_standard_button, {
    onClick: _ctx.confirmActionBar,
    disabled: !_ctx.authorActionBar && !_ctx.categoryActionBar
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('copy_speaches.speaches_setup.table.confirm').toString()), 1)];
    }),
    _: 1
  }, 8, ["onClick", "disabled"])])) : _createCommentVNode("", true)]);
}