import "core-js/modules/es.array.concat.js";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3c19441e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "rights-management-container"
};
var _hoisted_2 = {
  class: "rights-management-container__item"
};
var _hoisted_3 = {
  class: "rights-summary"
};
var _hoisted_4 = {
  class: "rights-summary__label"
};
var _hoisted_5 = {
  class: "rights-summary__total-count"
};
var _hoisted_6 = {
  class: "rights-content"
};
var _hoisted_7 = {
  class: "rights-content__item"
};
var _hoisted_8 = {
  class: "rights-content__item__label"
};
var _hoisted_9 = {
  class: "rights-content__item"
};
var _hoisted_10 = {
  class: "rights-content__item__label"
};
var _hoisted_11 = {
  class: "rights-content__item"
};
var _hoisted_12 = {
  class: "rights-content__item__label"
};
var _hoisted_13 = {
  class: "rights-content__item"
};
var _hoisted_14 = {
  class: "rights-content__item__label"
};
var _hoisted_15 = {
  class: "rights-content__item"
};
var _hoisted_16 = {
  class: "rights-content__item__label"
};
var _hoisted_17 = {
  class: "rights-management-container__item"
};
var _hoisted_18 = {
  class: "rights-summary"
};
var _hoisted_19 = {
  class: "rights-summary__label"
};
var _hoisted_20 = {
  class: "rights-summary__total-count"
};
var _hoisted_21 = {
  class: "rights-content"
};
var _hoisted_22 = {
  class: "rights-content__item"
};
var _hoisted_23 = {
  class: "rights-content__item__label"
};
var _hoisted_24 = {
  class: "rights-content__item"
};
var _hoisted_25 = {
  class: "rights-content__item__label"
};
var _hoisted_26 = {
  class: "rights-content__item"
};
var _hoisted_27 = {
  class: "rights-content__item__label"
};
var _hoisted_28 = {
  class: "rights-management-container__item"
};
var _hoisted_29 = {
  class: "rights-summary"
};
var _hoisted_30 = {
  class: "rights-summary__label"
};
var _hoisted_31 = {
  class: "rights-summary__total-count"
};
var _hoisted_32 = {
  class: "rights-content"
};
var _hoisted_33 = {
  class: "rights-content__item"
};
var _hoisted_34 = {
  class: "rights-content__item__label"
};
var _hoisted_35 = {
  class: "rights-content__item"
};
var _hoisted_36 = {
  class: "rights-content__item__label"
};
var _hoisted_37 = {
  class: "rights-content__item"
};
var _hoisted_38 = {
  class: "rights-content__item__label"
};
var _hoisted_39 = {
  class: "rights-content__item"
};
var _hoisted_40 = {
  class: "rights-content__item__label"
};
var _hoisted_41 = {
  class: "rights-content__item"
};
var _hoisted_42 = {
  class: "rights-content__item__label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_switch = _resolveComponent("el-switch");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_w_icon, {
    icon: "home-group",
    class: _normalizeClass("rights-summary__icon ".concat(this.getTypeFor(_ctx.rightManagementGroupKey.MANAGE_PLATFORM)))
  }, null, 8, ["class"]), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('users_management.rights_management.platforms.title')), 1), _createElementVNode("span", _hoisted_5, _toDisplayString("".concat(this.getGroupValue(_ctx.rightManagementGroupKey.MANAGE_PLATFORM).active, "/").concat(this.getGroupValue(_ctx.rightManagementGroupKey.MANAGE_PLATFORM).total)), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('users_management.rights_management.platforms.connect')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.CONNECT_PLATFORM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.CONNECT_PLATFORM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('users_management.rights_management.platforms.disconnect')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.DISCONNECT_PLATFORM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.DISCONNECT_PLATFORM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('users_management.rights_management.platforms.create')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.CREATE_PLATFORM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.CREATE_PLATFORM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('users_management.rights_management.platforms.settings')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.SETTING_PLATFORM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.SETTING_PLATFORM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('users_management.rights_management.platforms.copy_speach')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.COPY_SPEACH),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.COPY_SPEACH);
    })
  }, null, 8, ["model-value", "disabled"])])])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createVNode(_component_w_icon, {
    icon: "account-group-outline",
    class: _normalizeClass("rights-summary__icon ".concat(this.getTypeFor(_ctx.rightManagementGroupKey.MANAGE_USER)))
  }, null, 8, ["class"]), _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('users_management.rights_management.users.title')), 1), _createElementVNode("span", _hoisted_20, _toDisplayString("".concat(this.getGroupValue(_ctx.rightManagementGroupKey.MANAGE_USER).active, "/").concat(this.getGroupValue(_ctx.rightManagementGroupKey.MANAGE_USER).total)), 1)]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('users_management.rights_management.users.create')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.CREATE_USER),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.CREATE_USER);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_24, [_createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('users_management.rights_management.users.update')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.MODIFY_USER),
    disabled: !_ctx.isUserUpdateAivalable || _ctx.isMe,
    onChange: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.MODIFY_USER);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('users_management.rights_management.users.delete')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.DELETE_USER),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.DELETE_USER);
    })
  }, null, 8, ["model-value", "disabled"])])])]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [_createVNode(_component_w_icon, {
    icon: "security",
    class: _normalizeClass("rights-summary__icon ".concat(this.getTypeFor(_ctx.rightManagementGroupKey.MANAGE_AUTHITEM)))
  }, null, 8, ["class"]), _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('users_management.rights_management.auths.title')), 1), _createElementVNode("span", _hoisted_31, _toDisplayString("".concat(this.getGroupValue(_ctx.rightManagementGroupKey.MANAGE_AUTHITEM).active, "/").concat(this.getGroupValue(_ctx.rightManagementGroupKey.MANAGE_AUTHITEM).total)), 1)]), _createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, [_createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('users_management.rights_management.auths.create')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.CREATE_AUTHITEM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.CREATE_AUTHITEM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_35, [_createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t('users_management.rights_management.auths.update')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.UPDATE_AUTHITEM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.UPDATE_AUTHITEM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_37, [_createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.$t('users_management.rights_management.auths.delete')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.DELETE_AUTHITEM),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.DELETE_AUTHITEM);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_39, [_createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t('users_management.rights_management.auths.link_creation')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.CREATE_AUTHLINK),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.CREATE_AUTHLINK);
    })
  }, null, 8, ["model-value", "disabled"])]), _createElementVNode("div", _hoisted_41, [_createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t('users_management.rights_management.auths.link_delete')), 1), _createVNode(_component_el_switch, {
    class: "rights-content__item__switch",
    "model-value": this.getValueForKey(_ctx.rightManagementKey.DELETE_AUTHLINK),
    disabled: !_ctx.isUserUpdateAivalable,
    onChange: _cache[12] || (_cache[12] = function ($event) {
      return _ctx.changeValueFor(_ctx.rightManagementKey.DELETE_AUTHLINK);
    })
  }, null, 8, ["model-value", "disabled"])])])])]);
}