import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$createdUser;
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_text_input = _resolveComponent("w-text-input");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_w_dialog = _resolveComponent("w-dialog");
  var _component_account_creation_notification_dialog = _resolveComponent("account-creation-notification-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_w_dialog, {
    visible: _ctx.dialogIsVisible,
    title: _ctx.$t('users_creation_dialog.title'),
    onDialogClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.closeUserCreationDialog();
    })
  }, {
    footer: _withCtx(function () {
      return [_createVNode(_component_w_standard_button, {
        "is-loading": _ctx.loading,
        class: "create-user-dialog-btn",
        type: "primary",
        "min-width": "117px",
        disabled: _ctx.shouldDisableSubmit(),
        onClick: _ctx.addUser
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('users_creation_dialog.submit_btn')), 1)];
        }),
        _: 1
      }, 8, ["is-loading", "disabled", "onClick"])];
    }),
    default: _withCtx(function () {
      return [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_w_text_input, {
        type: "text",
        fieldLabel: _ctx.$t('users_creation_dialog.login'),
        placeholder: "rdupond",
        value: _ctx.login,
        size: "large",
        width: "512px",
        onInputChanged: _ctx.handleLogin,
        onBlur: _ctx.fillprofil
      }, null, 8, ["fieldLabel", "value", "onInputChanged", "onBlur"]), _createVNode(_component_w_text_input, {
        type: "text",
        fieldLabel: _ctx.$t('users_creation_dialog.firstName'),
        placeholder: "Robert",
        value: _ctx.firstname,
        onInputChanged: _ctx.handleFirstName,
        size: "large",
        width: "512px"
      }, null, 8, ["fieldLabel", "value", "onInputChanged"]), _createVNode(_component_w_text_input, {
        type: "text",
        fieldLabel: _ctx.$t('users_creation_dialog.lastName'),
        placeholder: "Dupond",
        value: _ctx.lastname,
        onInputChanged: _ctx.handleLastName,
        size: "large",
        width: "512px"
      }, null, 8, ["fieldLabel", "value", "onInputChanged"]), _createVNode(_component_w_text_input, {
        type: "email",
        fieldLabel: _ctx.$t('users_creation_dialog.email'),
        placeholder: "robert.dupont@speach.me",
        value: _ctx.contact,
        onInputChanged: _ctx.handleContact,
        size: "large",
        width: "512px"
      }, null, 8, ["fieldLabel", "value", "onInputChanged"])];
    }),
    _: 1
  }, 8, ["visible", "title"]), _createVNode(_component_account_creation_notification_dialog, {
    "user-first-name": (_ctx$createdUser = _ctx.createdUser) === null || _ctx$createdUser === void 0 ? void 0 : _ctx$createdUser.firstName,
    "password-url": _ctx.createdAccountUrl,
    "toggle-dialog": _ctx.toggleAccountCreationNotificationDialog,
    onDialogClose: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.toggleAccountCreationNotificationDialog = false;
    })
  }, null, 8, ["user-first-name", "password-url", "toggle-dialog"])]);
}