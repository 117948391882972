import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-20f0e79c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = {
  class: "copy-speach-stepper"
};
var _hoisted_3 = {
  class: "copy-speach-stepper__body"
};
var _hoisted_4 = {
  class: "buttons-bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_MainTitle = _resolveComponent("MainTitle");
  var _component_el_step = _resolveComponent("el-step");
  var _component_el_steps = _resolveComponent("el-steps");
  var _component_ChoosePlatform = _resolveComponent("ChoosePlatform");
  var _component_ChooseSpeach = _resolveComponent("ChooseSpeach");
  var _component_ConfigureSpeach = _resolveComponent("ConfigureSpeach");
  var _component_ResultCopySpeach = _resolveComponent("ResultCopySpeach");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_CenterTop = _resolveComponent("CenterTop");
  return _openBlock(), _createBlock(_component_CenterTop, null, {
    default: _withCtx(function () {
      var _ctx$sourcePlatform;
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MainTitle, null, {
        title: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('copy_speaches.title').toString()), 1)];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_steps, {
        active: _ctx.activeStep,
        "align-center": "",
        "finish-status": "success"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_step, {
            title: _ctx.$t('copy_speaches.steps_bar.source').toString()
          }, null, 8, ["title"]), _createVNode(_component_el_step, {
            title: _ctx.$t('copy_speaches.steps_bar.speaches_to_copy').toString()
          }, null, 8, ["title"]), _createVNode(_component_el_step, {
            title: _ctx.$t('copy_speaches.steps_bar.destination').toString()
          }, null, 8, ["title"]), _createVNode(_component_el_step, {
            title: _ctx.$t('copy_speaches.steps_bar.speaches_setup').toString()
          }, null, 8, ["title"]), _createVNode(_component_el_step, {
            title: _ctx.$t('copy_speaches.steps_bar.speaches_result_process').toString()
          }, null, 8, ["title"])];
        }),
        _: 1
      }, 8, ["active"]), _createElementVNode("div", _hoisted_3, [_ctx.activeStep === 0 ? (_openBlock(), _createBlock(_component_ChoosePlatform, {
        key: 0,
        onSelected: _ctx.selectSourcePlaform,
        "selected-platform": _ctx.sourcePlatform,
        "is-source": true
      }, null, 8, ["onSelected", "selected-platform"])) : _createCommentVNode("", true), _ctx.activeStep === 1 ? (_openBlock(), _createBlock(_component_ChooseSpeach, {
        key: 1
      })) : _createCommentVNode("", true), _ctx.activeStep === 2 ? (_openBlock(), _createBlock(_component_ChoosePlatform, {
        key: 2,
        onSelected: _ctx.selectDestinationPlatform,
        "selected-platform": _ctx.destinationPlatform,
        "excluded-platform": (_ctx$sourcePlatform = _ctx.sourcePlatform) === null || _ctx$sourcePlatform === void 0 ? void 0 : _ctx$sourcePlatform.platformID,
        "is-source": false
      }, null, 8, ["onSelected", "selected-platform", "excluded-platform"])) : _createCommentVNode("", true), _ctx.activeStep === 3 ? (_openBlock(), _createBlock(_component_ConfigureSpeach, {
        key: 3
      })) : _createCommentVNode("", true), _ctx.activeStep === 4 ? (_openBlock(), _createBlock(_component_ResultCopySpeach, {
        key: 4,
        onSpeachesCopied: _ctx.enableLastStepButtons
      }, null, 8, ["onSpeachesCopied"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_ctx.activeStep !== 0 && _ctx.activeStep < 4 ? (_openBlock(), _createBlock(_component_w_standard_button, {
        key: 0,
        type: "default",
        class: "btn-left-step",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.updateActiveStep(_ctx.activeStep - 1);
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_w_icon, {
            icon: "arrow-left"
          }), _createTextVNode(" " + _toDisplayString(_ctx.$t('copy_speaches.steps_navigate_buttons.previous')), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _ctx.activeStep === 4 ? (_openBlock(), _createBlock(_component_w_standard_button, {
        key: 1,
        class: _normalizeClass('btn-left-step'),
        type: "default",
        disabled: !_ctx.lastStepButtonsEnabled,
        onClick: _ctx.reset
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('copy_speaches.steps_navigate_buttons.copy_more_speaches').toString()), 1)];
        }),
        _: 1
      }, 8, ["disabled", "onClick"])) : _createCommentVNode("", true), _ctx.activeStep < 4 ? (_openBlock(), _createBlock(_component_w_standard_button, {
        key: 2,
        type: "primary",
        disabled: !_ctx.nextEnabled,
        class: "btn-right-step",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.updateActiveStep(_ctx.activeStep + 1);
        })
      }, {
        default: _withCtx(function () {
          return [_ctx.iconLeftNext ? (_openBlock(), _createBlock(_component_w_icon, {
            key: 0,
            icon: _ctx.iconLeftNext
          }, null, 8, ["icon"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(_ctx.labelNext) + " ", 1), _ctx.iconRightNext ? (_openBlock(), _createBlock(_component_w_icon, {
            key: 1,
            icon: _ctx.iconRightNext
          }, null, 8, ["icon"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["disabled"])) : (_openBlock(), _createBlock(_component_w_standard_button, {
        key: 3,
        type: "primary",
        disabled: !_ctx.lastStepButtonsEnabled,
        onClick: _ctx.goToHome,
        class: "btn-right-step"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('copy_speaches.steps_navigate_buttons.back_to_home').toString()), 1)];
        }),
        _: 1
      }, 8, ["disabled", "onClick"]))])])])];
    }),
    _: 1
  });
}