import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4a09274a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "userContainer"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "nameContainer"
};
var _hoisted_4 = {
  class: "labelContainer"
};
var _hoisted_5 = {
  class: "labelContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Card = _resolveComponent("Card");
  return _openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        src: require('./assets/portrait.jpeg'),
        class: "logoUser"
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.user.firstName), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user.lastName), 1)])])];
    }),
    _: 1
  });
}