import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_search_bar = _resolveComponent("w-search-bar");
  var _component_platform_card_selectable = _resolveComponent("platform-card-selectable");
  var _component_grid_item = _resolveComponent("grid-item");
  var _component_grid_square_layout = _resolveComponent("grid-square-layout");
  var _component_alert_dialog = _resolveComponent("alert-dialog");
  return _openBlock(), _createElementBlock("div", null, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_w_search_bar, {
    class: "search-platforms-bar",
    placeholder: _ctx.$t('copy_speaches.search.search_platform_placeholder').toString(),
    width: "100%",
    height: "40px",
    onOnInputUpdate: _ctx.onSearchInputUpdate,
    value: _ctx.platformFilter
  }, null, 8, ["placeholder", "onOnInputUpdate", "value"]), _createVNode(_component_grid_square_layout, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platforms, function (platform) {
        return _openBlock(), _createBlock(_component_grid_item, {
          key: platform.id
        }, {
          default: _withCtx(function () {
            var _ctx$selectedPlatform;
            return [_createVNode(_component_platform_card_selectable, {
              platform: platform,
              selected: ((_ctx$selectedPlatform = _ctx.selectedPlatform) === null || _ctx$selectedPlatform === void 0 ? void 0 : _ctx$selectedPlatform.platformID) === platform.platformID,
              onClick: function onClick($event) {
                return _ctx.togglePlateforme(platform);
              },
              disabled: _ctx.excludedPlatform === platform.platformID
            }, null, 8, ["platform", "selected", "onClick", "disabled"])];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }), _ctx.copySpeachNotEligiblePlatform ? (_openBlock(), _createBlock(_component_alert_dialog, {
    key: 1,
    visible: !!_ctx.copySpeachNotEligiblePlatform,
    title: _ctx.$t('copy_speaches.copy_speaches_disabled_dialog.title').toString(),
    onDialogClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.copySpeachNotEligiblePlatform = null;
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString(_ctx.$t('copy_speaches.copy_speaches_disabled_dialog.message', {
        platformName: _ctx.copySpeachNotEligiblePlatform.name
      }).toString()), 1)];
    }),
    _: 1
  }, 8, ["visible", "title"])) : _createCommentVNode("", true)]);
}