import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "tablePlatformContainer"
};
var _hoisted_2 = {
  slot: "empty"
};
var _hoisted_3 = {
  class: "no-result-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    ref: "tablePlatform",
    data: _ctx.platformsFull,
    "row-class-name": "table-row",
    stripe: "",
    onCellClick: _ctx.goToTheLine,
    hoverable: ""
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        prop: "platform.name",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.name'),
        width: "200",
        sortable: "",
        "class-name": "platform-name__cell"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbSpeachPublish",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.published_speaches'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbSpeachToPublish",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.speaches_to_publish'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbSpeachToValidate",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.speaches_to_validate'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbUser",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.users'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbConnectedMonth",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.users_connected_this_month'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbSpeachMaker",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.speach_maker'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbAdmin",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.administrators'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbNewSpeachWeek",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.new_speaches_this_week'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbNewSpeachMonth",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.new_speaches_last_month'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbNewSpeach3Month",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.new_speaches_last_3_month'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "summary.nbNewSpeach6Month",
        label: _ctx.$t('platforms_management.connected_platforms_section.platforms_table.cols.new_speaches_last_6_month'),
        width: "160",
        sortable: ""
      }, null, 8, ["label"]), _createElementVNode("template", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_w_icon, {
        icon: "emoticon-sad-outline"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('platforms_management.connected_platforms_section.platforms_table.on_no_result_found')), 1)])])];
    }),
    _: 1
  }, 8, ["data", "onCellClick"])]);
}