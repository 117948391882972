import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-03319f18"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "auth-items-table"
};
var _hoisted_2 = {
  class: "auth-item-name-column"
};
var _hoisted_3 = {
  class: "auth-item-name-column__value"
};
var _hoisted_4 = {
  slot: "empty"
};
var _hoisted_5 = {
  class: "no-result-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_w_icon_button = _resolveComponent("w-icon-button");
  var _component_router_link = _resolveComponent("router-link");
  var _component_el_table = _resolveComponent("el-table");
  var _component_auth_item_delete_dialog = _resolveComponent("auth-item-delete-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_table, {
    ref: "authItemsTable",
    data: _ctx.authItems
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        label: _ctx.$t('authentications_management.authentication_items_table.name_col')
      }, {
        default: _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_2, [_ctx.isAuthenticationValid(scope.row) ? (_openBlock(), _createBlock(_component_w_icon, {
            key: 0,
            icon: "check-decagram-outline",
            class: "auth-item-name-column__icon is-success"
          })) : (_openBlock(), _createBlock(_component_w_icon, {
            key: 1,
            icon: "alert",
            class: "auth-item-name-column__icon is-error"
          })), _createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.name), 1)])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "type",
        label: _ctx.$t('authentications_management.authentication_items_table.login_col')
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: "",
        width: "100px"
      }, {
        default: _withCtx(function (scope) {
          return [_createVNode(_component_router_link, {
            to: {
              name: 'settingsSaml2',
              params: {
                authItemID: scope.row.authItemID,
                name: scope.row.name
              }
            }
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_w_icon_button, {
                iconClass: "pencil-outline",
                class: "action-btn auth-item-edit-btn",
                disabled: !_ctx.isUpdateAuthItem
              }, null, 8, ["disabled"])];
            }),
            _: 2
          }, 1032, ["to"]), _createVNode(_component_w_icon_button, {
            iconClass: "delete-outline",
            class: "action-btn auth-item-delete-btn",
            disabled: !_ctx.isDeleteAuthItem,
            onClick: function onClick($event) {
              return _ctx.deleteAuthItem(scope.row);
            }
          }, null, 8, ["disabled", "onClick"])];
        }),
        _: 1
      }), _createElementVNode("template", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_w_icon, {
        icon: "emoticon-sad-outline"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('authentications_management.authentication_items_table.on_no_result_found').toString()), 1)])])];
    }),
    _: 1
  }, 8, ["data"]), _createVNode(_component_auth_item_delete_dialog, {
    "toggle-dialog": _ctx.toggleAuthItemDeleteDialog,
    onDialogClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.toggleAuthItemDeleteDialog = false;
    }),
    onDeleteConfirm: _ctx.confirmAuthItemDelete
  }, null, 8, ["toggle-dialog", "onDeleteConfirm"])]);
}