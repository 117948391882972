import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1edb4f1f"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "gridContainer"
};
var _hoisted_2 = {
  class: "attributes-block"
};
var _hoisted_3 = {
  class: "attributes-block__container"
};
var _hoisted_4 = {
  class: "field-container"
};
var _hoisted_5 = {
  class: "field-container__label"
};
var _hoisted_6 = {
  class: "field-container__select"
};
var _hoisted_7 = {
  class: "field-container__value-example"
};
var _hoisted_8 = {
  class: "field-container"
};
var _hoisted_9 = {
  class: "field-container__label"
};
var _hoisted_10 = {
  class: "field-container__select"
};
var _hoisted_11 = {
  class: "field-container__value-example"
};
var _hoisted_12 = {
  class: "attributes-block"
};
var _hoisted_13 = {
  class: "attributes-block__container"
};
var _hoisted_14 = {
  class: "field-container"
};
var _hoisted_15 = {
  class: "field-container__label"
};
var _hoisted_16 = {
  class: "field-container__select"
};
var _hoisted_17 = {
  class: "field-container__value-example"
};
var _hoisted_18 = {
  class: "field-container"
};
var _hoisted_19 = {
  class: "field-container__label"
};
var _hoisted_20 = {
  class: "field-container__select"
};
var _hoisted_21 = {
  class: "field-container__value-example"
};
var _hoisted_22 = {
  class: "attributes-block"
};
var _hoisted_23 = {
  class: "attributes-block__container"
};
var _hoisted_24 = {
  class: "field-container"
};
var _hoisted_25 = {
  class: "field-container__label"
};
var _hoisted_26 = {
  class: "field-container__select"
};
var _hoisted_27 = {
  class: "field-container__value-example"
};
var _hoisted_28 = {
  key: 0,
  class: "field-container"
};
var _hoisted_29 = {
  class: "field-container__label"
};
var _hoisted_30 = {
  class: "field-container__rule"
};
var _hoisted_31 = {
  key: 0,
  class: "field-container__rule-value"
};
var _hoisted_32 = {
  class: "field-container"
};
var _hoisted_33 = {
  class: "field-container__label"
};
var _hoisted_34 = {
  class: "field-container__select"
};
var _hoisted_35 = {
  class: "field-container__value-example"
};
var _hoisted_36 = {
  key: 1,
  class: "field-container"
};
var _hoisted_37 = {
  class: "field-container__label"
};
var _hoisted_38 = {
  class: "field-container__rule"
};
var _hoisted_39 = {
  key: 0,
  class: "field-container__rule-value"
};
var _hoisted_40 = {
  class: "field-container"
};
var _hoisted_41 = {
  class: "field-container__label"
};
var _hoisted_42 = {
  class: "field-container__select"
};
var _hoisted_43 = {
  class: "field-container__value-example"
};
var _hoisted_44 = {
  key: 2,
  class: "field-container"
};
var _hoisted_45 = {
  class: "field-container__label"
};
var _hoisted_46 = {
  class: "field-container__rule"
};
var _hoisted_47 = {
  key: 0,
  class: "field-container__rule-value"
};
var _hoisted_48 = {
  class: "attributes-group-blocks"
};
var _hoisted_49 = {
  class: "attributes-block"
};
var _hoisted_50 = {
  class: "attributes-block__container"
};
var _hoisted_51 = {
  class: "field-container"
};
var _hoisted_52 = {
  class: "field-container__label"
};
var _hoisted_53 = {
  class: "field-container__select"
};
var _hoisted_54 = {
  class: "field-container__value-example"
};
var _hoisted_55 = {
  key: 0,
  class: "field-container"
};
var _hoisted_56 = {
  class: "field-container__label"
};
var _hoisted_57 = {
  class: "field-container__rule"
};
var _hoisted_58 = {
  key: 0,
  class: "field-container__rule-value"
};
var _hoisted_59 = {
  class: "attributes-block"
};
var _hoisted_60 = {
  class: "attributes-block__container"
};
var _hoisted_61 = {
  class: "field-container"
};
var _hoisted_62 = {
  class: "field-container__label"
};
var _hoisted_63 = {
  class: "field-container__select"
};
var _hoisted_64 = {
  class: "field-container__value-example"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_label = _resolveComponent("w-label");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_w_tooltip = _resolveComponent("w-tooltip");
  var _component_el_option = _resolveComponent("el-option");
  var _component_w_select = _resolveComponent("w-select");
  var _component_w_text_input = _resolveComponent("w-text-input");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    width: "auto",
    title: _ctx.$t('authentications_management.auth_item.auth_mappings_modal.title').toString(),
    class: "attributes-mappings-dialog",
    onDialogClose: _cache[17] || (_cache[17] = function ($event) {
      return _ctx.onDialogClose();
    })
  }, {
    footer: _withCtx(function () {
      return [_createVNode(_component_w_standard_button, {
        "is-loading": _ctx.loading,
        class: "save-mapping-btn",
        type: "primary",
        "min-width": "190px",
        onClick: _ctx.recordMapping
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.save_button').toString()), 1)];
        }),
        _: 1
      }, 8, ["is-loading", "onClick"])];
    }),
    default: _withCtx(function () {
      return [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_w_label, {
        class: "attributes-block__title"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.identification_block.title').toString()), 1)];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.identification_block.email_field').toString()) + " : ", 1), _createVNode(_component_w_tooltip, {
        content: _ctx.$t('authentications_management.auth_item.auth_mappings_modal.identification_block.email_tooltip').toString(),
        placement: "top"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_w_icon, {
            icon: "information-outline",
            size: "16px",
            class: "field-container__label__tooltip-icon"
          })];
        }),
        _: 1
      }, 8, ["content"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_w_select, {
        width: "184px",
        name: "emailAttributeMap",
        value: _ctx.emailAttributeMap,
        onChange: _cache[0] || (_cache[0] = function (value) {
          return _ctx.emailAttributeMap = value;
        })
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authItem.authItemAttributes, function (attribute) {
            return _openBlock(), _createBlock(_component_el_option, {
              value: attribute.name,
              key: attribute.name,
              label: attribute.name
            }, null, 8, ["value", "label"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["value"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLastValueForName(_this.emailAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.identification_block.login_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_10, [_createVNode(_component_w_select, {
        width: "184px",
        name: "loginAttributeMap",
        value: _ctx.loginAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[1] || (_cache[1] = function (value) {
          return _ctx.loginAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getLastValueForName(_this.loginAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])])])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_w_label, {
        class: "attributes-block__title"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.informations_block.title').toString()), 1)];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.informations_block.firstname_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_16, [_createVNode(_component_w_select, {
        width: "184px",
        name: "firstNameAttributeMap",
        value: _ctx.firstNameAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[2] || (_cache[2] = function (value) {
          return _ctx.firstNameAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getLastValueForName(_this.firstNameAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.informations_block.lastname_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_20, [_createVNode(_component_w_select, {
        width: "184px",
        name: "lastNameAttributeMap",
        value: _ctx.lastNameAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[3] || (_cache[3] = function (value) {
          return _ctx.lastNameAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getLastValueForName(_this.lastNameAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])])])]), _createElementVNode("div", _hoisted_22, [_createVNode(_component_w_label, {
        class: "attributes-block__title"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.title').toString()), 1)];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.speachmaker_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_26, [_createVNode(_component_w_select, {
        width: "184px",
        name: "trainerAttributeMap",
        value: _ctx.trainerAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[4] || (_cache[4] = function (value) {
          return _ctx.trainerAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.getLastValueForName(_this.trainerAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])]), _ctx.trainerAttributeMap !== _ctx.UNMAPPED ? (_openBlock(), _createElementBlock("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [_createVNode(_component_w_icon, {
        icon: "axis-y-arrow-lock",
        class: "field-container__label__icon"
      })]), _createElementVNode("div", _hoisted_30, [_createVNode(_component_w_select, {
        width: "184px",
        name: "ruleTrainer",
        value: _ctx.ruleTrainer,
        options: _ctx.additionalRuleOptions,
        onChange: _cache[5] || (_cache[5] = function (value) {
          return _ctx.ruleTrainer = value;
        })
      }, null, 8, ["value", "options"])]), _ctx.ruleTrainer !== _ctx.authItemAttributeRuleMapping.EXIST ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createVNode(_component_w_text_input, {
        prefixWIcon: "redo",
        value: _ctx.ruleValueTrainer,
        width: "134px",
        onInputChanged: _cache[6] || (_cache[6] = function (value) {
          return _ctx.ruleValueTrainer = value;
        })
      }, null, 8, ["value"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.approver_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_34, [_createVNode(_component_w_select, {
        width: "184px",
        name: "approverAttributeMap",
        value: _ctx.approverAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[7] || (_cache[7] = function (value) {
          return _ctx.approverAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.getLastValueForName(_this.approverAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])]), _ctx.approverAttributeMap !== _ctx.UNMAPPED ? (_openBlock(), _createElementBlock("div", _hoisted_36, [_createElementVNode("div", _hoisted_37, [_createVNode(_component_w_icon, {
        icon: "axis-y-arrow-lock",
        class: "field-container__label__icon"
      })]), _createElementVNode("div", _hoisted_38, [_createVNode(_component_w_select, {
        width: "184px",
        name: "ruleApprover",
        value: _ctx.ruleApprover,
        options: _ctx.additionalRuleOptions,
        onChange: _cache[8] || (_cache[8] = function (value) {
          return _ctx.ruleApprover = value;
        })
      }, null, 8, ["value", "options"])]), _ctx.ruleApprover !== _ctx.authItemAttributeRuleMapping.EXIST ? (_openBlock(), _createElementBlock("div", _hoisted_39, [_createVNode(_component_w_text_input, {
        prefixWIcon: "redo",
        value: _ctx.ruleValueApprover,
        width: "134px",
        onInputChanged: _cache[9] || (_cache[9] = function (value) {
          return _ctx.ruleValueApprover = value;
        })
      }, null, 8, ["value"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_40, [_createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.admin_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_42, [_createVNode(_component_w_select, {
        width: "184px",
        name: "adminAttributeMap",
        value: _ctx.adminAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[10] || (_cache[10] = function (value) {
          return _ctx.adminAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.getLastValueForName(_this.adminAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])]), _ctx.adminAttributeMap !== _ctx.UNMAPPED ? (_openBlock(), _createElementBlock("div", _hoisted_44, [_createElementVNode("div", _hoisted_45, [_createVNode(_component_w_icon, {
        icon: "axis-y-arrow-lock",
        class: "field-container__label__icon"
      })]), _createElementVNode("div", _hoisted_46, [_createVNode(_component_w_select, {
        width: "184px",
        name: "ruleAdmin",
        value: _ctx.ruleAdmin,
        options: _ctx.additionalRuleOptions,
        onChange: _cache[11] || (_cache[11] = function (value) {
          return _ctx.ruleAdmin = value;
        })
      }, null, 8, ["value", "options"])]), _ctx.ruleAdmin !== _ctx.authItemAttributeRuleMapping.EXIST ? (_openBlock(), _createElementBlock("div", _hoisted_47, [_createVNode(_component_w_text_input, {
        prefixWIcon: "redo",
        value: _ctx.ruleValueAdmin,
        width: "134px",
        onInputChanged: _cache[12] || (_cache[12] = function (value) {
          return _ctx.ruleValueAdmin = value;
        })
      }, null, 8, ["value"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_48, [_createElementVNode("div", _hoisted_49, [_createVNode(_component_w_label, {
        class: "attributes-block__title"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.groups_affectation_block.title').toString()), 1)];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_50, [_createElementVNode("div", _hoisted_51, [_createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.groups_affectation_block.group_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_53, [_createVNode(_component_w_select, {
        width: "184px",
        name: "groupAttributeMap",
        value: _ctx.groupAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[13] || (_cache[13] = function (value) {
          return _ctx.groupAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.getLastValueForName(_this.groupAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])]), _ctx.groupAttributeMap !== _ctx.UNMAPPED ? (_openBlock(), _createElementBlock("div", _hoisted_55, [_createElementVNode("div", _hoisted_56, [_createVNode(_component_w_icon, {
        icon: "axis-y-arrow-lock",
        class: "field-container__label__icon"
      })]), _createElementVNode("div", _hoisted_57, [_createVNode(_component_w_select, {
        width: "184px",
        name: "ruleGroup",
        value: _ctx.ruleGroup,
        options: _ctx.authItemListOptions,
        onChange: _cache[14] || (_cache[14] = function (value) {
          return _ctx.ruleGroup = value;
        })
      }, null, 8, ["value", "options"])]), _ctx.ruleGroup !== _ctx.authItemAttributeRuleMapping.LIST ? (_openBlock(), _createElementBlock("div", _hoisted_58, [_createVNode(_component_w_text_input, {
        prefixWIcon: "redo",
        value: _ctx.ruleValueGroup,
        width: "134px",
        onInputChanged: _cache[15] || (_cache[15] = function (value) {
          return _ctx.ruleValueGroup = value;
        })
      }, null, 8, ["value"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_59, [_createVNode(_component_w_label, {
        class: "attributes-block__title"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.additional_authorization_block.title').toString()), 1)];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_60, [_createElementVNode("div", _hoisted_61, [_createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.$t('authentications_management.auth_item.auth_mappings_modal.additional_authorization_block.authorization_field').toString()) + " :", 1), _createElementVNode("div", _hoisted_63, [_createVNode(_component_w_select, {
        width: "184px",
        name: "authorizationAttributeMap",
        value: _ctx.authorizationAttributeMap,
        options: _ctx.authItemAttributesOptions,
        onChange: _cache[16] || (_cache[16] = function (value) {
          return _ctx.authorizationAttributeMap = value;
        })
      }, null, 8, ["value", "options"])]), _createVNode(_component_w_tooltip, {
        content: _ctx.valueExplanationToolTip,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.getLastValueForName(_this.authorizationAttributeMap)), 1)];
        }),
        _: 1
      }, 8, ["content"])])])])])])];
    }),
    _: 1
  }, 8, ["visible", "title"]);
}