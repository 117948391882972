import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5140cbdc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "settingItemContainer"
};
var _hoisted_2 = {
  class: "line"
};
var _hoisted_3 = {
  class: "iconLine"
};
var _hoisted_4 = {
  class: "labelLine"
};
var _hoisted_5 = {
  class: "switchLine"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_switch = _resolveComponent("el-switch");
  var _component_w_tooltip = _resolveComponent("w-tooltip");
  var _component_Card = _resolveComponent("Card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_Card, {
    class: "setting-item-card"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_w_tooltip, {
        content: _ctx.tooltip,
        placement: "bottom",
        class: "setting-item-card__tooltip"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_w_icon, {
            icon: _ctx.icon
          }, null, 8, ["icon"])]), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_switch, {
            disabled: _ctx.disabled,
            value: _ctx.settingValue,
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.value = $event;
            }),
            onChange: _ctx.changeValue
          }, null, 8, ["disabled", "value", "modelValue", "onChange"])])])];
        }),
        _: 1
      }, 8, ["content"])];
    }),
    _: 1
  })]);
}