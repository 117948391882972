import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5cdfd4d3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "authItemContainer"
};
var _hoisted_2 = {
  class: "titleAuthItem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_CardInteractive = _resolveComponent("CardInteractive");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.createAuthRequest && _ctx.createAuthRequest.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_CardInteractive, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.authItem.name), 1), _createElementVNode("p", null, [_createVNode(_component_w_icon, {
        icon: "account-group",
        size: "48px",
        class: "auth-item-icon"
      })])])];
    }),
    _: 1
  })]);
}