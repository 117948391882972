import "core-js/modules/es.string.trim.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Banner = _resolveComponent("Banner");
  var _component_w_text_input = _resolveComponent("w-text-input");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.$t('platforms_management.connect_platform_dialog.title'),
    onDialogClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.onDialogClose();
    })
  }, {
    footer: _withCtx(function () {
      var _ctx$url;
      return [_createVNode(_component_w_standard_button, {
        "is-loading": _ctx.isLoading,
        class: "connect-platform-btn",
        type: "primary",
        "min-width": "117px",
        disabled: !((_ctx$url = _ctx.url) !== null && _ctx$url !== void 0 && _ctx$url.trim()),
        onClick: _ctx.addPlatform
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('platforms_management.connect_platform_dialog.connect_btn')), 1)];
        }),
        _: 1
      }, 8, ["is-loading", "disabled", "onClick"])];
    }),
    default: _withCtx(function () {
      return [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_w_text_input, {
        type: "url",
        fieldLabel: _ctx.$t('platforms_management.connect_platform_dialog.platform_url_field_label'),
        placeholder: "https://my-company.".concat(_ctx.CLUSTER_DOMAIN),
        value: _ctx.url,
        width: "512px",
        size: "large",
        onInputChanged: _ctx.updateUrl
      }, null, 8, ["fieldLabel", "placeholder", "value", "onInputChanged"])];
    }),
    _: 1
  }, 8, ["visible", "title"]);
}