import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1c699c1c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "selected-item-container"
};
var _hoisted_2 = {
  class: "sort-value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock(_component_el_dropdown, {
    class: _normalizeClass("dropdown ".concat(_ctx.dropdownIsOpened ? 'dropdown--is-opened' : 'dropdown--is-closed')),
    placement: _ctx.placement,
    trigger: "click",
    size: "large",
    onCommand: _ctx.onCommandHandled,
    onVisibleChange: _ctx.onVisibilityChanges
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, {
        class: "sort-dropdown-menu"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, function (item) {
            return _openBlock(), _createBlock(_component_el_dropdown_item, {
              class: _normalizeClass("sort-dropdown-menu__item ".concat(item.isSelected ? 'checked' : '')),
              key: item.key,
              divided: item.divided,
              command: item.key
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
                  class: _normalizeClass("value-icon mdi ".concat(item.icon))
                }, null, 2), _createTextVNode(" " + _toDisplayString(item.value), 1)]), item.isSelected ? (_openBlock(), _createBlock(_component_w_icon, {
                  key: 0,
                  icon: "check",
                  size: "22px",
                  class: "item-checked-icon"
                })) : _createCommentVNode("", true)];
              }),
              _: 2
            }, 1032, ["class", "divided", "command"]);
          }), 128))];
        }),
        _: 1
      })];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, function (item) {
        return _openBlock(), _createBlock(_component_w_icon, {
          icon: item.icon,
          size: "21px",
          class: _normalizeClass("thumbnail-icon ".concat(!item.isSelected ? 'thumbnail-icon--hide' : ''))
        }, null, 8, ["icon", "class"]);
      }), 256))])];
    }),
    _: 1
  }, 8, ["class", "placement", "onCommand", "onVisibleChange"]);
}