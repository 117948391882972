import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-27c22cbc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_MainTitle = _resolveComponent("MainTitle");
  var _component_Banner = _resolveComponent("Banner");
  var _component_AuthLinksTable = _resolveComponent("AuthLinksTable");
  var _component_auth_link_form = _resolveComponent("auth-link-form");
  var _component_center_top = _resolveComponent("center-top");
  return _openBlock(), _createBlock(_component_center_top, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MainTitle, null, {
        title: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("authentication_links_management.overview.title").toString()), 1)];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_w_standard_button, {
            minWidth: "298px",
            disabled: !_ctx.isCreateAuthLink,
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.toggleAuthLinkFormDialog = true;
            })
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_w_icon, {
                icon: "plus"
              }), _createTextVNode(" " + _toDisplayString(_ctx.$t("authentication_links_management.auth_link.create_btn").toString()), 1)];
            }),
            _: 1
          }, 8, ["disabled"])];
        }),
        _: 1
      }), !!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_AuthLinksTable)]), _createVNode(_component_auth_link_form, {
        "toggle-auth-link-form-dialog": _ctx.toggleAuthLinkFormDialog,
        onDialogClose: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.toggleAuthLinkFormDialog = false;
        })
      }, null, 8, ["toggle-auth-link-form-dialog"])];
    }),
    _: 1
  });
}