import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cadb6bea"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "slidedeck-panel"
};
var _hoisted_2 = {
  class: "slideDeckBackgroundPanel"
};
var _hoisted_3 = {
  class: "inputColor"
};
var _hoisted_4 = {
  class: "buttonPanel"
};
var _hoisted_5 = {
  class: "blockLogo"
};
var _hoisted_6 = {
  key: 0,
  class: "imgPreviewContainer"
};
var _hoisted_7 = ["src"];
var _hoisted_8 = {
  class: "upload-block"
};
var _hoisted_9 = {
  class: "icon-block"
};
var _hoisted_10 = {
  class: "upload-draggable-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_w_label = _resolveComponent("w-label");
  var _component_native_color_picker = _resolveComponent("native-color-picker");
  var _component_w_icon = _resolveComponent("w-icon");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_el_upload = _resolveComponent("el-upload");
  var _component_i18n_t = _resolveComponent("i18n-t");
  var _component_confirm_dialog = _resolveComponent("confirm-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_w_label, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.settings_view.slidedeck_customization_section.font_color')), 1)];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_native_color_picker, {
    width: "230px",
    "default-color": _ctx.txtColor,
    onOnSelection: _ctx.saveSlideDeckBackground
  }, null, 8, ["default-color", "onOnSelection"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_w_standard_button, {
    class: "roundedButton",
    type: "danger",
    minWidth: "32px",
    disabled: this.logoDataUrl === '',
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.displaySlideckDeleteDialog = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_w_icon, {
        icon: "delete",
        class: "delete-slide-deck-icon"
      })];
    }),
    _: 1
  }, 8, ["disabled"])])])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_upload, {
    action: "#",
    "auto-upload": false,
    "show-file-list": false,
    accept: "image/*",
    drag: "",
    "on-change": _ctx.onFileSelected
  }, {
    default: _withCtx(function () {
      return [_ctx.logoDataUrl !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("img", {
        class: "logoPreview",
        src: _ctx.logoDataUrl
      }, null, 8, _hoisted_7), _createElementVNode("div", {
        class: "fakeTxt",
        style: _normalizeStyle('color:' + _ctx.txtColor + ';')
      }, _toDisplayString(_ctx.$t('platform_detail.settings_view.slidedeck_customization_section.upload.title_placeholder')), 5)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, [_createVNode(_component_w_icon, {
        icon: "upload-outline"
      })]), _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('platform_detail.settings_view.slidedeck_customization_section.upload.upload_placeholder')), 1)])];
    }),
    _: 1
  }, 8, ["on-change"])]), _ctx.displaySlideckDeleteDialog ? (_openBlock(), _createBlock(_component_confirm_dialog, {
    key: 0,
    title: _ctx.$t("platform_detail.settings_view.slidedeck_delete_dialog.title").toString(),
    visible: _ctx.displaySlideckDeleteDialog,
    "is-delete-action": true,
    "confirm-btn-title": _ctx.$t("platform_detail.settings_view.slidedeck_delete_dialog.confirm_btn").toString(),
    onDialogClose: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.displaySlideckDeleteDialog = false;
    }),
    onOnConfirmed: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.deleteSlideDeckBackground();
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_i18n_t, {
        scope: "global",
        keypath: "platform_detail.settings_view.slidedeck_delete_dialog.message.value",
        tag: "div"
      }, {
        delete: _withCtx(function () {
          return [_createElementVNode("b", null, _toDisplayString(_ctx.$t('platform_detail.settings_view.slidedeck_delete_dialog.message.delete').toString()), 1)];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["title", "visible", "confirm-btn-title"])) : _createCommentVNode("", true)]);
}