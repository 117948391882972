import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1312ab46"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "user-cell"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "user-text"
};
var _hoisted_4 = {
  class: "name-text"
};
var _hoisted_5 = {
  class: "mail-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.image,
    class: "user-img"
  }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.firstName + " " + _ctx.lastName), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.email), 1)])]);
}