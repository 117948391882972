import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-650311d0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "settingsContainer"
};
var _hoisted_2 = {
  key: 1,
  class: "slide-deck-items-block"
};
var _hoisted_3 = {
  class: "slide-deck-items-block__item"
};
var _hoisted_4 = {
  class: "slide-deck-items-block__item"
};
var _hoisted_5 = {
  class: "slide-deck-items-block__item"
};
var _hoisted_6 = {
  class: "slide-deck-items-block__item"
};
var _hoisted_7 = {
  class: "slide-deck-items-block__item"
};
var _hoisted_8 = {
  class: "slide-deck-items-block__item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_Banner = _resolveComponent("Banner");
  var _component_Separator = _resolveComponent("Separator");
  var _component_SettingItem = _resolveComponent("SettingItem");
  var _component_grid_item = _resolveComponent("grid-item");
  var _component_grid_layout = _resolveComponent("grid-layout");
  var _component_SlideDeckBackgroundItem = _resolveComponent("SlideDeckBackgroundItem");
  return _ctx.slideDeckSettingReady ? (_openBlock(), _createElementBlock("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_Separator, {
    icon: "play-speed",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.speach_player_section.title')
  }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_player_section.auto_forward.title'),
            keyValue: _ctx.autoNextKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_player_section.auto_forward.description'),
            icon: "fast-forward-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_player_section.video_sd_play.title'),
            keyValue: _ctx.defaultSDKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_player_section.video_sd_play.description'),
            icon: "high-definition-box"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_player_section.subtitle_auto_display_in_video.title'),
            keyValue: _ctx.subtitleAutoDisplayKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_player_section.subtitle_auto_display_in_video.description'),
            icon: "subtitles-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_player_section.video_download.title'),
            keyValue: _ctx.videoDownload,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_player_section.video_download.description'),
            icon: "cloud-download-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_player_section.pdf_download.title'),
            keyValue: _ctx.pdfDownload,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_player_section.pdf_download.description'),
            icon: "file-download-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_Separator, {
    icon: "playlist-edit",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.speach_editor_section.title')
  }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_editor_section.transcript.title'),
            keyValue: _ctx.transcriptKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_editor_section.transcript.description'),
            icon: "subtitles-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_Separator, {
    icon: "progress-upload",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.speach_publishing_section.title')
  }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_publishing_section.workflow.title'),
            keyValue: _ctx.workflowKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_publishing_section.workflow.description'),
            icon: "sitemap"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_publishing_section.workflow_mandatory.title'),
            keyValue: _ctx.workflowMandatoryKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_publishing_section.workflow_mandatory.description'),
            icon: "filter-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.speach_publishing_section.public_publication.title'),
            keyValue: _ctx.publishAdminKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.speach_publishing_section.public_publication.description'),
            icon: "cloud-download-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_Separator, {
    icon: "lock",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.platform_security_section.title')
  }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.platform_security_section.anonymous_access.title'),
            platform: _ctx.platform,
            keyValue: _ctx.whatchAnonymousKey,
            tooltip: _ctx.$t('platform_detail.settings_view.platform_security_section.anonymous_access.description'),
            icon: "incognito"
          }, null, 8, ["label", "platform", "keyValue", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.platform_security_section.self_enrollment.title'),
            platform: _ctx.platform,
            keyValue: _ctx.allowCreateAccountKey,
            tooltip: _ctx.$t('platform_detail.settings_view.platform_security_section.self_enrollment.description'),
            icon: "account-plus-outline"
          }, null, 8, ["label", "platform", "keyValue", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.platform_security_section.google_indexation.title'),
            platform: _ctx.platform,
            keyValue: _ctx.allowSearchEngineKey,
            tooltip: _ctx.$t('platform_detail.settings_view.platform_security_section.google_indexation.description'),
            icon: "magnify"
          }, null, 8, ["label", "platform", "keyValue", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.platform_security_section.user_authoring.title'),
            platform: _ctx.platform,
            keyValue: _ctx.newUserIsFormateurKey,
            tooltip: _ctx.$t('platform_detail.settings_view.platform_security_section.user_authoring.description'),
            icon: "account-outline"
          }, null, 8, ["label", "platform", "keyValue", "tooltip"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_Separator, {
    icon: "magnify",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.search_options_section.title')
  }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.search_options_section.search_among_users.title'),
            keyValue: _ctx.userSearchKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.search_options_section.search_among_users.description'),
            icon: "account-search-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.search_options_section.search_in_text_content.title'),
            keyValue: _ctx.searchContentKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.search_options_section.search_in_text_content.description'),
            icon: "text-box-search-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_Separator, {
    icon: "comment-account",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.users_interaction_section.title')
  }, null, 8, ["color", "label"]), _createVNode(_component_grid_layout, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.users_interaction_section.rates_and_comments.title'),
            keyValue: _ctx.commentEnabledKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.users_interaction_section.rates_and_comments.description'),
            icon: "comment-account-outline"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.users_interaction_section.share.title'),
            keyValue: _ctx.activatePartageKey,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.users_interaction_section.share.description'),
            icon: "share"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      }), _createVNode(_component_grid_item, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_SettingItem, {
            label: _ctx.$t('platform_detail.settings_view.users_interaction_section.support.title'),
            keyValue: _ctx.supportBubbleEnabled,
            platform: _ctx.platform,
            tooltip: _ctx.$t('platform_detail.settings_view.users_interaction_section.support.description'),
            icon: "face-agent"
          }, null, 8, ["label", "keyValue", "platform", "tooltip"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_Separator, {
    icon: "presentation-play",
    color: _ctx.platform.color,
    label: _ctx.$t('platform_detail.settings_view.slidedeck_customization_section.title')
  }, null, 8, ["color", "label"]), _ctx.slideDeckSetting != undefined ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_SlideDeckBackgroundItem, {
    position: 1,
    platform: _ctx.platform,
    slideDeckBackgroud: this.slideDeckBackground(1)
  }, null, 8, ["platform", "slideDeckBackgroud"])]), _createVNode(_Transition, {
    name: "zoom"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_4, [_createVNode(_component_SlideDeckBackgroundItem, {
        position: 2,
        platform: _ctx.platform,
        slideDeckBackgroud: _this.slideDeckBackground(2)
      }, null, 8, ["platform", "slideDeckBackgroud"])], 512), [[_vShow, _ctx.slideDeckSetting.slideDeckBackground.length > 0]])];
    }),
    _: 1
  }), _createVNode(_Transition, {
    name: "zoom"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_5, [_createVNode(_component_SlideDeckBackgroundItem, {
        position: 3,
        platform: _ctx.platform,
        slideDeckBackgroud: _this.slideDeckBackground(3)
      }, null, 8, ["platform", "slideDeckBackgroud"])], 512), [[_vShow, _ctx.slideDeckSetting.slideDeckBackground.length > 1]])];
    }),
    _: 1
  }), _createVNode(_Transition, {
    name: "zoom"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_6, [_createVNode(_component_SlideDeckBackgroundItem, {
        position: 4,
        platform: _ctx.platform,
        slideDeckBackgroud: _this.slideDeckBackground(4)
      }, null, 8, ["platform", "slideDeckBackgroud"])], 512), [[_vShow, _ctx.slideDeckSetting.slideDeckBackground.length > 2]])];
    }),
    _: 1
  }), _createVNode(_Transition, {
    name: "zoom"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_7, [_createVNode(_component_SlideDeckBackgroundItem, {
        position: 5,
        platform: _ctx.platform,
        slideDeckBackgroud: _this.slideDeckBackground(5)
      }, null, 8, ["platform", "slideDeckBackgroud"])], 512), [[_vShow, _ctx.slideDeckSetting.slideDeckBackground.length > 3]])];
    }),
    _: 1
  }), _createVNode(_Transition, {
    name: "zoom"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_8, [_createVNode(_component_SlideDeckBackgroundItem, {
        position: 6,
        platform: _ctx.platform,
        slideDeckBackgroud: _this.slideDeckBackground(6)
      }, null, 8, ["platform", "slideDeckBackgroud"])], 512), [[_vShow, _ctx.slideDeckSetting.slideDeckBackground.length > 4]])];
    }),
    _: 1
  })])) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
}