import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_i18n_t = _resolveComponent("i18n-t");
  var _component_w_standard_button = _resolveComponent("w-standard-button");
  var _component_standard_notification_dialog = _resolveComponent("standard-notification-dialog");
  return _openBlock(), _createBlock(_component_standard_notification_dialog, {
    class: "delete_confirmation_dialog",
    title: _ctx.$t('users_management.user_deletion_warning_dialog.title'),
    "toggle-dialog": _ctx.visible,
    onDialogClose: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.onDialogClose();
    })
  }, {
    footer: _withCtx(function () {
      return [_createVNode(_component_w_standard_button, {
        type: "danger",
        "min-width": "70px",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.onDeleteConfirm();
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('users_management.user_deletion_warning_dialog.confirm_button').toString()), 1)];
        }),
        _: 1
      })];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_i18n_t, {
        scope: "global",
        keypath: "users_management.user_deletion_warning_dialog.message.value",
        tag: "div"
      }, {
        delete: _withCtx(function () {
          return [_createElementVNode("b", null, _toDisplayString(_ctx.$t("users_management.user_deletion_warning_dialog.message.delete").toString()), 1)];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["title", "toggle-dialog"]);
}