import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4747d4c3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "check-all-message"
};
var _hoisted_2 = {
  key: 0,
  class: "check-all-message-display"
};
var _hoisted_3 = {
  key: 1,
  class: "check-all-message-display"
};
var _hoisted_4 = {
  key: 0,
  class: "div-search"
};
var _hoisted_5 = {
  key: 0,
  class: "div-search"
};
var _hoisted_6 = {
  key: 0,
  class: "div-search category-search"
};
var _hoisted_7 = {
  key: 0,
  class: "div-search"
};
var _hoisted_8 = {
  key: 0,
  class: "empty-state"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_i18n_t = _resolveComponent("i18n-t");
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_header_table = _resolveComponent("header-table");
  var _component_w_text_input = _resolveComponent("w-text-input");
  var _component_title_cell = _resolveComponent("title-cell");
  var _component_user_cell = _resolveComponent("user-cell");
  var _component_select_category = _resolveComponent("select-category");
  var _component_cell = _resolveComponent("cell");
  var _component_el_table = _resolveComponent("el-table");
  var _directive_observe_visibility = _resolveDirective("observe-visibility");
  var _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.checkAllMessage || _ctx.uncheckAllMessage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.checkAllMessage ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_i18n_t, {
    scope: "global",
    keypath: "copy_speaches.search.speaches_selected"
  }, {
    numberOfSpeaches: _withCtx(function () {
      return [_createElementVNode("b", null, _toDisplayString(_ctx.numberOfSpeachesChecked) + " ", 1)];
    }),
    _: 1
  }), _createElementVNode("a", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.selectAllFromBack && _ctx.selectAllFromBack.apply(_ctx, arguments);
    }),
    class: "select-all-speach-toggle-link"
  }, _toDisplayString(_ctx.$t('copy_speaches.search.select_all_speaches', {
    numberOfSpeaches: _ctx.totalOfSpeachesSearch
  })), 1)])) : _createCommentVNode("", true), _ctx.uncheckAllMessage ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_i18n_t, {
    scope: "global",
    keypath: "copy_speaches.search.all_speaches_selected"
  }, {
    numberOfSpeaches: _withCtx(function () {
      return [_createElementVNode("b", null, " " + _toDisplayString(_ctx.totalOfSpeachesSearch) + " ", 1)];
    }),
    _: 1
  }), _createElementVNode("a", {
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    function () {
      return _ctx.clearAllFromBack && _ctx.clearAllFromBack.apply(_ctx, arguments);
    }),
    class: "select-all-speach-toggle-link"
  }, _toDisplayString(_ctx.$t('copy_speaches.search.clear_all_speaches')), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    ref: _ctx.tableId,
    data: _ctx.tableData,
    stripe: "",
    size: "large",
    onSelect: _ctx.rowChecked,
    onSelectAll: _ctx.allChecked,
    "header-row-class-name": !_ctx.headerCheckable ? 'header-not-checkable' : '',
    class: _normalizeClass("speach-table ".concat(_ctx.checkAllMessage || _ctx.uncheckAllMessage ? 'speach-table--checks-msg-visible' : ''))
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        type: "selection",
        width: "55"
      }), _createVNode(_component_el_table_column, {
        field: "title",
        width: "450"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_to_copy.table.title_col').toString()
          }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_w_text_input, {
            value: _ctx.titleFilter,
            placeholder: _ctx.$t('copy_speaches.speaches_to_copy.table.title_search').toString(),
            width: "100%",
            class: "search-speaches-input",
            onInputChanged: _ctx.onTitleFilterUpdated
          }, null, 8, ["value", "placeholder", "onInputChanged"])])) : _createCommentVNode("", true)];
        }),
        default: _withCtx(function (scope) {
          return [_withDirectives((_openBlock(), _createElementBlock("div", null, [scope.row.title ? (_openBlock(), _createBlock(_component_title_cell, {
            key: 0,
            title: scope.row.title,
            image: scope.row.image
          }, null, 8, ["title", "image"])) : _createCommentVNode("", true)])), [[_directive_observe_visibility, function (isVisible) {
            return _ctx.visibilityChanged(isVisible, scope.row);
          }]])];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "author",
        "min-width": "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_to_copy.table.author_col').toString()
          }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_w_text_input, {
            value: _ctx.authorFilter,
            placeholder: _ctx.$t('copy_speaches.speaches_to_copy.table.author_search').toString(),
            width: "100%",
            class: "search-speaches-input",
            onInputChanged: _ctx.onAuthorFilterUpdated
          }, null, 8, ["value", "placeholder", "onInputChanged"])])) : _createCommentVNode("", true)];
        }),
        default: _withCtx(function (scope) {
          var _scope$row$user, _scope$row$user2, _scope$row$user3, _scope$row$user4;
          return [scope.row.user ? (_openBlock(), _createBlock(_component_user_cell, {
            key: 0,
            image: (_scope$row$user = scope.row.user) === null || _scope$row$user === void 0 ? void 0 : _scope$row$user.image,
            "first-name": (_scope$row$user2 = scope.row.user) === null || _scope$row$user2 === void 0 ? void 0 : _scope$row$user2.firstName,
            "last-name": (_scope$row$user3 = scope.row.user) === null || _scope$row$user3 === void 0 ? void 0 : _scope$row$user3.lastName,
            email: (_scope$row$user4 = scope.row.user) === null || _scope$row$user4 === void 0 ? void 0 : _scope$row$user4.email
          }, null, 8, ["image", "first-name", "last-name", "email"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "category",
        "min-width": "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_to_copy.table.category_col').toString()
          }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_select_category, {
            name: "select-category",
            "default-value": _ctx.categoryFilter,
            platform: _ctx.platformId,
            placeholder: _ctx.$t('copy_speaches.speaches_to_copy.table.category_filter').toString(),
            "add-empty-value": true,
            onInput: _ctx.onCategoryUpdated
          }, null, 8, ["default-value", "platform", "placeholder", "onInput"])])) : _createCommentVNode("", true)];
        }),
        default: _withCtx(function (scope) {
          var _scope$row$category;
          return [scope.row.category ? (_openBlock(), _createBlock(_component_cell, {
            key: 0,
            text: (_scope$row$category = scope.row.category) === null || _scope$row$category === void 0 ? void 0 : _scope$row$category.name
          }, null, 8, ["text"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }), _createVNode(_component_el_table_column, {
        field: "visibility",
        width: "150"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_header_table, {
            label: _ctx.$t('copy_speaches.speaches_to_copy.table.visibility_col').toString()
          }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true)];
        }),
        default: _withCtx(function (scope) {
          return [scope.row.visibility ? (_openBlock(), _createBlock(_component_cell, {
            key: 0,
            text: scope.row.visibility
          }, null, 8, ["text"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("copy_speaches.speaches_to_copy.table.empty_state").toString()), 1)) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["data", "onSelect", "onSelectAll", "header-row-class-name", "class"])), [[_directive_loading, _ctx.loading]])], 64);
}