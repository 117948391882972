import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock(_component_el_tooltip, {
    "popper-class": "w-tooltip-popper",
    content: _ctx.content,
    placement: _ctx.placement,
    effect: _ctx.effect
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
  }, 8, ["content", "placement", "effect"]);
}